//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import GlobalMixin from '../shared/GlobalMixins.js'
import GamePlayMixin from "../shared/GamePlayMixin";
import { getAssetSizeSuffix } from 'src/shared/responsiveAssetsLoader.js';
const uri = require('../components/GamePlay/Common/data/URLsCommon.js');


export default {
  name: 'Home',
  mixins: [GlobalMixin, GamePlayMixin],
  components: {
    VueperSlides, VueperSlide
  },
  data: function () {
    return {
      gameSelector: null,
      first_slide: true,
      last_slide: false,
      currentSlide: 0,
      CDN: uri
    }
  },
  methods: {
    sliderReady (eventName, params) {
      // console.log(eventName, params)
    },
    goToNextSlide() {
      this.first_slide = 0;
      this.last_slide = this.availableGames.length - 1;

      // if this.currentSlide is less than this.last_side we increment it, else we set it to 0
      this.$refs.gameSelector.goToSlide(this.currentSlide < this.last_slide ? this.currentSlide + 1 : 0);
    },
    goToPrevSlide() {
      this.first_slide = 0;
      this.last_slide = this.availableGames.length - 1;

      // if this.currentSlide is greater than this.first_slide we decrement it, else we set it to this.last_slide
      this.$refs.gameSelector.goToSlide(this.currentSlide > this.first_slide ? this.currentSlide - 1 : this.last_slide);
    },
    beforeSlide (slides) {
      let currentSlide = slides.nextSlide !== undefined ? slides.nextSlide.index : 0;
      this.first_slide = (currentSlide === 0);
      this.last_slide = (currentSlide === this.availableGames.length - 1);
      this.currentSlide = currentSlide;
    },
    initAudio () {
      this.initPlayer();
    },
    initGameSelector (e, event) {
      this.currentSlide = this.lastClickedGame ? this.lastClickedGame : 3;
      this.$refs.gameSelector.$children.forEach(function (element, index) {
        if (index === 3) {
          console.log('vuperSlider', element.$el);
        }

        })
    },
    getSlideImage (slide) {
      const size = getAssetSizeSuffix();
      return uri + slide.image.replace('images/', `images/${size}`);
    },
  },
  computed: {
    availableGames() {
      if (this.inMission) {
        return this.games;
      } else {
        return this.all_games;
      }
    },
    slideOffset () {
      return {
        '--offset': this.currentSlide
      };
    },
    currentGameDescription () {
      return this.all_games[this.currentSlide].description;
    }
  },
  watch: {
    currentSlide () {
      this.characters(this.currentGameDescription)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.loggedIn) {
        next({ name: 'login' });
      }
    })
  },
  async created () {

    this.setLoading(false);
    this.unloadMusic();

    this.setCurrentGame(false);
    this.setTheme({
      backgroundColor: '#f3f3f3',
      foregroundColor: '#333333',
      backgroundImage: uri + 'arcade-background.webp',
      secondaryColor: 'black',
      buttonColor: 'green'
    });
  },
  mounted () {
    this.delay = 30;

    console.log('lastClickedGame', this.lastClickedGame)
    this.currentSlide = this.lastClickedGame !== null ? this.lastClickedGame : 3;
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });


    const that = this;
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 37) {
        that.gameSelector.previous();
      }

      if (event.keyCode === 39) {
        that.gameSelector.next();
      }
    });
  }
}
